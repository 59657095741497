@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/5bc35956b6aaec48.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/f72ddc4266d0d2ba.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/f68460597dbfd4ac.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/ba79ba900f883b7a.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/4ce555f565b723fa.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/85e2ef83be05cb2d.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/9c939eba328264d0.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/351db43edbd78dbf.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/0a447f1d8c7c4fab.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__proximaFont_Fallback_8dea09';src: local("Arial");ascent-override: 79.64%;descent-override: 21.17%;line-gap-override: 0.00%;size-adjust: 99.19%
}.__className_8dea09 {font-family: '__proximaFont_8dea09', '__proximaFont_Fallback_8dea09'
}.__variable_8dea09 {--font-proxima: '__proximaFont_8dea09', '__proximaFont_Fallback_8dea09'
}

.a-button--size-md {
  border-radius: 20px / 50%;
  padding: 6px 22px;
}

.a-button--size-lg {
  border-radius: 24px / 50%;
  padding: 8px 30px;
}

.a-button--size-xl {
  border-radius: 28px / 50%;
  padding: 10px 46px;
}

.a-svg {
  display: block;
}

.a-svg svg {
  height: inherit;
  width: inherit;
  display: block;
  margin: auto;
}

.a-svg.fill-current [fill] {
  fill: currentcolor;
}

.a-svg.stroke-current [stroke] {
  stroke: currentcolor;
}

.a-input {
  display: block;
  width: 100%;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(217 222 223 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.75rem;
  --tw-text-opacity: 1;
  color: rgb(87 96 108 / var(--tw-text-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.a-input:disabled {
  opacity: 0.5;
}

.a-input:required {
  --tw-border-opacity: 1;
  border-color: rgb(16 168 186 / var(--tw-border-opacity));
}

.a-input.invalid {
  background-color: #fcfcc9;
  border-color: #fcb281;
}

.a-select {

  display: block;

  width: 100%;

  -webkit-appearance: none;

     -moz-appearance: none;

          appearance: none;

  border-radius: 0px;

  border-width: 1px;

  --tw-border-opacity: 1;

  border-color: rgb(217 222 223 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;

  background-color: rgb(255 255 255 / var(--tw-bg-opacity));

  padding: 0.75rem;

  --tw-text-opacity: 1;

  color: rgb(87 96 108 / var(--tw-text-opacity));

  outline: 2px solid transparent;

  outline-offset: 2px;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;

  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  transition-duration: 150ms;

  background: #fff url(/_next/static/media/arrow-down.a11c92e3.svg) calc(100% - 10px) 50% / 34px no-repeat;
  padding-inline-end: 40px;
}

[dir="rtl"] .a-select {
    background-position: 10px 50%;
}

.a-select.regions {

  border-radius: 9999px;

  border-width: 2px;

  padding-left: 1.5rem;

  font-size: 13px;

  line-height: 1.85;

  letter-spacing: 0.05em;

  font-weight: 700;

  --tw-text-opacity: 1;

  color: rgb(144 148 149 / var(--tw-text-opacity));
}

.a-select option {

  --tw-bg-opacity: 1;

  background-color: rgb(255 255 255 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;

  color: rgb(87 96 108 / var(--tw-text-opacity));
}

.a-select:disabled {

  opacity: 0.5;
}

.a-select:required {

  --tw-border-opacity: 1;

  border-color: rgb(16 168 186 / var(--tw-border-opacity));
}

.a-select.invalid {
  background-color: #fcfcc9;
  border-color: #fcb281;
}

.a-textarea {
  display: block;
  width: 100%;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(217 222 223 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.75rem;
  --tw-text-opacity: 1;
  color: rgb(87 96 108 / var(--tw-text-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.a-textarea:disabled {
  opacity: 0.5;
}

.a-textarea:required {
  --tw-border-opacity: 1;
  border-color: rgb(16 168 186 / var(--tw-border-opacity));
}

.a-textarea.invalid {
  background-color: #fcfcc9;
  border-color: #fcb281;
}

.a-video::before {
  content: "";
  display: block;
  width: 100%;
}

.a-video__horizontal::before {
  padding-top: 56.25%;
}

.a-video__vertical::before {
  padding-top: 177.77%;
}

.a-video__content > iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.m-form-search__input {
  border-radius: 24px / 50%;
  padding: 10px 22px;
}

.m-form-search__message {
  bottom: -24px;
  inset-inline-start: 23px;
}

.m-image-wrapper {
    position: relative;
    z-index: 1;
}

.m-image-wrapper::after {
    content: '';
    position: absolute;
    width: 40%;
    height: 50%;
    z-index: -1;
}

.m-image-wrapper::before {
    content: '';
    position: absolute;
    width: 40%;
    height: 50%;
    background: white;
    z-index: -1;
}

.m-image-wrapper__sm::after, .m-image-wrapper__md::after {
    right: -10px;
    top: -10px;
}

.m-image-wrapper__sm::before, .m-image-wrapper__md::before {
    left: -10px;
    bottom: -10px;
}

.m-image-wrapper__type-white::after, .m-image-wrapper__type-white::before {
    background: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}

.m-image-wrapper__type-accent::after, .m-image-wrapper__type-accent::before {
    background: #FFA87A;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}

@media screen and (min-width: 768px) {
    .m-image-wrapper__md::after {
        right: -10px;
        top: -10px;
    }

    .m-image-wrapper__md::before {
        left: -10px;
        bottom: -10px;
    }
}

.m-modal {
  pointer-events: none;
}

.m-modal--open {
  pointer-events: all;
}

.m-modal__dim {
  background-color: rgba(0 0 0 / 40%);
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.m-modal--open .m-modal__dim {
  opacity: 1;
}

.m-modal__modal {
  left: 0;
  opacity: 0;
  top: 0;
  transform: translate3d(0, 30px, 0);
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;
}

@media (min-width: 768px) {
  .m-modal__modal {
    max-width: 700px;
  }

  .m-modal__modal--large {
    max-width: 1348px;
  }
}

.m-modal--open .m-modal__modal {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.2s ease-out 0.2s, transform 0.2s ease-out 0.2s;
}

@media (min-width: 768px) {
  .m-modal__modal {
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, calc(-50% + 30px), 0);
  }

  .m-modal--open .m-modal__modal {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
}

.m-pinned-subnav__toggle,
.m-pinned-subnav__button {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  min-height: 56px;
}

@media (min-width: 1024px) {

.m-pinned-subnav__toggle,
.m-pinned-subnav__button {
    min-height: auto;
}
  }

[dir="rtl"] .m-pinned-subnav__toggle-icon {
    transform: rotate(180deg);
}

.m-pinned-subnav__list {
  max-height: calc(100vh - var(--c-the-header-height, 72px));
}

@media (min-width: 1024px) {

.m-pinned-subnav__item {
    margin-inline-start: 3rem;
}

    .m-pinned-subnav__item:first-child {
      margin-inline-start: 0;
    }
  }

.m-video-modal__dim {
  background-color: rgba(46 48 48 / 80%);
}

.m-video-modal__video {
  max-width: 960px;
}

.m-article-link__ratio {
  height: 0;
  padding-top: 58.42%;
}

.m-article-link__arrow {
  transform: translateY(-2px);
  transition: transform 0.2s ease-out;
}

[dir="rtl"] .m-article-link__arrow {
    transform: translateY(-2px) rotate(180deg);
}

.m-article-link:hover .m-article-link__arrow {
  transform: translate3d(5px, -2px, 0);
}

[dir="rtl"] :is(.m-article-link:hover .m-article-link__arrow) {
    transform: translate3d(-5px, -2px, 0) rotate(180deg);
}

.m-pagination-dots__arrow {
    --tw-text-opacity: 1;
    color: rgb(16 168 186 / var(--tw-text-opacity));
}

.m-pagination-dots--theme_secondary-orange .m-pagination-dots__arrow {
    color: #de742e;
}

.m-pagination-dots__dot {
    --tw-bg-opacity: 1;
    background-color: rgb(144 148 149 / var(--tw-bg-opacity));
}

.m-pagination-dots__dot.is-active {
    --tw-bg-opacity: 1;
    background-color: rgb(16 168 186 / var(--tw-bg-opacity));
}

.m-pagination-dots--theme_secondary-orange .m-pagination-dots__dot {
    background-color: #de742e;
}

.m-pagination-dots--theme_secondary-orange .m-pagination-dots__dot.is-active {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

[dir="rtl"] .m-pagination-dots__arrow-icon {
    transform: rotate(180deg);
}

.m-pagination-arrow-circle > svg {
  height: 42px;
  width: 42px;
}

.m-pagination-dots__arrow.arrow-right {
  position: absolute;
  right: -2.5%;
  bottom: 80px;
}

.m-pagination-dots__arrow.arrow-left {
  position: absolute;
  left: -1.5%;
  bottom: 80px;
}

.m-pagination-dots__arrow.arrow-right > span:before, .m-pagination-dots__arrow.arrow-left > span:before {
  position: absolute;
  right: 15px;
}

/* responsive */

@media (max-width: 1024px) {
  .m-pagination-dots__arrow.arrow-right, .m-pagination-dots__arrow.arrow-left {
    display: none;
  }
}

.c-legacy-burger-icl__burger-bar {
  height: 2px;
  left: 0;
  top: 50%;
}

.c-legacy-burger-icl__burger-bar--top {
  transform: translateY(calc(-4px - 50%));
}

.c-legacy-burger-icl__burger-bar--middle {
  transform: translateY(-50%);
}

.c-legacy-burger-icl__burger-bar--bottom {
  transform: translateY(calc(4px - 50%));
}

.active .c-legacy-burger-icl__burger-bar--top {
  transform: translateY(-50%) rotate(45deg);
}

.active .c-legacy-burger-icl__burger-bar--middle {
  opacity: 0;
}

.active .c-legacy-burger-icl__burger-bar--bottom {
  transform: translateY(-50%) rotate(-45deg);
}

.c-comparison-table__row__us > div:first-of-type, .c-comparison-table__row-accent {
    position: relative;
}

.c-comparison-table__row__us > div:first-of-type:not(.empty)::after, .c-comparison-table__row-accent::after {
    content: '';
    display: block;
    width: 100%;
    height: 142%;
    border-left-width: 2px;
    border-right-width: 2px;
    border-color: #FFA87A;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.no-shadow::after {
    box-shadow: none !important;
}

.c-comparison-table__row__us:last-of-type > div:first-of-type:not(.empty)::after {
    border-bottom-width: 2px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.c-comparison-table__row__us:last-of-type {
    border-bottom-width: 1px;
}

.c-comparison-table__row-accent::after {
    top: -30px;
    border-top-width: 2px;
    height: 276%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.c-comparison-table__row__us > div:first-of-type > *, .c-comparison-table__row-accent > * {
    position: relative;
    z-index: 3;
}

.c-comparison-table__row-svg-cont svg > path {
    stroke-width: 3px;
}

.c-comparison-table__row__us-svg-cont {
    padding: 2px 0 8px;
}

.c-expanded-cta-section {
    position: relative;
    background: url(/_next/static/media/wave-expanded-cta-bottom-sm.e6afda23.svg) no-repeat center / cover;
    z-index: 1;
    padding: 175px 0;
    color: white;
    overflow: hidden;
}

.c-expanded-cta-section::after {
    content: '';
    background: url(/_next/static/media/wave-expanded-cta-top-sm.f8f4a49a.svg) no-repeat center / cover;
    position: absolute;
    top: 30px;
    bottom: 10px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-expanded-cta-section__icons::after, .c-expanded-cta-section__icons::before {
    content: '';
    position: absolute;
    z-index: 1;
}

.c-expanded-cta-section__icons::before {
    width: 525px;
    height: 330px;
    top: 20%;
    left: calc(525px / 2.5 * -1);
    background: url('/img/lens-icon-bg.png') 50% / contain no-repeat;
}

.c-expanded-cta-section__copy h3 {
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 1.5rem;
}

.c-expanded-cta-section__copy blockquote {
    font-size: 0.875rem;
    display: inline-block;
    margin-top: 40px;
    font-style: normal;
}

.c-expanded-cta-section > div > div > div.flex.justify-center > a > span {
    font-size: 18px;
}

@media screen and (min-width: 768px) {
    .c-expanded-cta-section {
        margin-top: -65px;
        background-image: url(/_next/static/media/wave-expanded-cta-bottom.d933aa1f.svg);
    }

    .c-expanded-cta-section::after {
        background-image: url(/_next/static/media/wave-expanded-cta-top.94e02946.svg);
        top: -24px;
        bottom: 20px
    }

    .c-expanded-cta-section__icons::after {
        width: 530px;
        height: 530px;
        top: -5px;
        left: calc(530px / 2 * -1);
        background: url('/img/eye-drop-icon-bg.png') 50% / contain no-repeat;
        opacity: 0.15;
        filter: brightness(0) invert(1);
    }

    .c-expanded-cta-section__icons::before {
        top: 25%;
        right: calc(525px / 2.5 * -1);
        left: unset;
    }
}

@media screen and (min-width: 1460px) {
    .c-expanded-cta-section::after {
        background-image: url(/_next/static/media/wave-expanded-cta-top.94e02946.svg);
        top: 8px;
        bottom: 20px
    }
}

.c-the-header__nav-dropdown {
  opacity: 0;
  pointer-events: none;
}

.c-the-header__nav-item:hover .c-the-header__nav-dropdown {
  opacity: 1;
  pointer-events: auto;
}

.c-the-header__button {
  border-radius: 20px / 50%;
  padding: 6px 22px;
}

.c-the-header__progress-bar {
  height: 4px;
}

.c-the-header__progress-bar-bar {
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.2s ease-out;
}

[dir="rtl"] .c-the-header__progress-bar-bar {
    transform-origin: right;
}

.c-the-menu__main {
  height: var(--c-the-header-height, 72px);
}

.c-the-menu__logo-graphic {
  height: 32px;
  width: 160px;
}

.c-the-menu__sub-list {
  position: absolute;
  top: 0px;
  z-index: 10;
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(87 96 108 / var(--tw-bg-opacity));
  inset-inline: 0px;

  transform: translate3d(100%, 0, 0);
  transition: transform 0.2s ease-out;
}

[dir="rtl"] .c-the-menu__sub-list {
    transform: translate3d(-100%, 0, 0);
}

.c-the-menu__sub-list.c-the-menu__sub-list--active {
    transform: translate3d(0, 0, 0);
  }

.c-the-menu__item {
  border-color: #909495aa;
}

.c-the-menu__list--secondary {
  background-color: #40454e;
}

.c-menu__socials-list {
  background-color: #40454e;
}

.c-the-menu__arrow-icon {
  transform: translateY(-2px);
}

[dir="rtl"] .c-the-menu__arrow-icon {
    transform: translateY(-2px) rotate(180deg);
}

.c-the-footer__newsletter {
  max-width: 640px;
}

.c-the-footer__links-item {
  text-transform: uppercase;
}

.c-the-footer__links-item::after {
  --tw-bg-opacity: 1;
  background-color: rgb(144 148 149 / var(--tw-bg-opacity));
  margin-inline-start: 0.5rem;

  content: "";
  display: inline-block;
  height: 10px;
  width: 1px;
}

.c-the-footer__links-item:last-child::after {
  display: none;
}

.c-the-footer__menu-item {
  font-size: 13px;
  line-height: 1.85;
  letter-spacing: 0.05em;
  font-weight: 700;
  text-transform: uppercase;

  color: #b7bbbc;
}

.c-the-footer__extra {
  --tw-text-opacity: 1;
  color: rgb(144 148 149 / var(--tw-text-opacity));
}

.c-the-footer__extra a {
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.c-the-footer__extra a:hover {
  --tw-text-opacity: 1;
  color: rgb(217 222 223 / var(--tw-text-opacity));
}

.c-the-footer__extra p {
  padding-top: 1rem;
}

.c-the-footer__loading-icon {
  animation: loading-icon-animation 1s infinite linear;
}

@keyframes loading-icon-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.c-masthead__logo {
  width: 266px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

.c-masthead__blocks--fade {
    position: relative !important;
}

.c-masthead__block--fade {
    opacity: 1 !important;
    pointer-events: auto !important;
    position: relative !important;
}
  }

.c-masthead__block--media {
  filter: brightness(0.85);
  padding-top: 72px;
}

@media screen and (min-width: 768px) {
  .c-masthead__block--media {
    padding-top: 34px;
  }
}

.c-masthead__block--scale {
  transform: scaleX(-1);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

.c-masthead__block--frames {
    opacity: 1 !important;
    pointer-events: auto !important;
}

.c-masthead.is-active .c-masthead__block--frames {
      position: fixed !important;
}
  }

.c-masthead__blocks--normal {
  margin-top: -100vh;
}

.c-masthead__body {
  max-width: 660px;
}

@media (min-width: 768px) {
  .c-masthead__body--large {
    max-width: 904px;
  }

  .c-masthead__body--with-logo {
    max-width: 451px;
  }
}

.c-masthead__scroll-icon {
  transition: transform 0.2s ease-out;
}

.c-masthead__scroll-text:hover .c-masthead__scroll-icon {
  transform: translate3d(0, 5px, 0);
}

.c-masthead__spacer {
  height: 76px;
}

.c-hero__wrapper {
    position: relative;
    background: no-repeat center / cover;
    padding: 100px 24px 0;
    margin: 90px 0 0;
    color: white;
}

.c-hero__wrapper::after {
    content: '';
    background: no-repeat center / cover;
    position: absolute;
    top: 20px;
    bottom: 10px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-hero__bg-lg {
    background-image: url("/img/bg-bottom-wave-lg.svg");
}

.c-hero__bg-lg::after {
    background-image: url("/img/bg-top-wave-lg.svg");
}

.c-hero__bg-md::after {
    top: 0;
    background-image: url(/_next/static/media/wave-hero-top-sm.08fad006.svg);
}

.c-hero__bg-md {
    padding: 75px 10px;
    background-image: url(/_next/static/media/wave-hero-bottom-sm.1fb57abf.svg);
}

.c-hero__wrapper .c-hero__columns {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    margin-top: 30px;
}

.c-hero__image-wrapper > img, .c-hero__image-wrapper > div {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}

.c-hero__featured-content {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 36px;
    color: #57606C;
}

.c-hero__featured-content ul {
    list-style: disc;
    padding-left: 12px;
}

.c-hero__featured-content h3 {
    color: #098B9A;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
}

.c-hero__scroll-btn-wrapper {
    position: relative;
    left: 0;
    right: 0;
    bottom: 10px;
    z-index: 6;
}

.c-hero__scroll-btn-wrapper .c-hero__scroll-btn {
    display: block;
    margin: auto;
    width: 68px;
    height: 68px;
    border-radius: 100%;
    color: #098B9A;
    background: #FFFFFF;
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.3);
}

.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 20%;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 40px;
    border: 1px solid #888;
    border-radius: 0.5em;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 600px !important;
}

.modal-content > p > span {
     font-style: italic;
}

.close {
    position: absolute;
    top: -20px;
    right: -10px;
    float: right;
    font-size: 32px;
    width: 55px;
    height: 55px;

}

.close:hover,
.close:focus {
    text-decoration: none;
    cursor: pointer;
}

.only-desktop {
    display: none;
  }

/* Responsive */

@media screen and (min-width: 768px) {
    .c-hero__wrapper {
        padding: 100px 4.5rem 0;
    }

    .c-hero__wrapper, .c-hero__wrapper::after {
        background-position: top center;
    }

    .c-hero__wrapper .c-hero__columns {
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 60px;
    }

    .c-hero__wrapper .c-hero__image-wrapper {
        width: -moz-max-content;
        width: max-content;
        max-width: 55%;
    }

    .c-hero__featured-content {
        margin-top: 40px;
    }

    .c-hero__bg-lg::after {
        top: 20px;
        background-image: url("/img/bg-top-wave-lg.svg");
    }

    .c-hero__bg-md::after {
        top: 0;
        background-image: url(/_next/static/media/wave-hero-bottom-md.dbd3986e.svg);
    }

    .c-hero__bg-md {
        padding: 4vw 24px 5vw;
        background-image: url("/img/bg-bottom-wave-md.svg");
        margin-top: 110px;
    }

    .c-hero__bg-md.small-hero {
        padding: 75px 10px 20px 100px;
        background: url(/_next/static/media/wave-contact-bottom.5c8d0c92.svg) no-repeat center / cover;
        z-index: 1;
    }

      .c-hero__bg-md.small-hero::after {
        content: '';
        background: url(/_next/static/media/wave-contact-top.838c3492.svg) no-repeat center / cover;
        position: absolute;
        top: 8px;
        bottom: 20px;
        left: 0;
        width: 100%;
        z-index: -1;
      }

      .modal-content {
        background-color: #fefefe;
        margin: 5% auto;
        padding: 50px;
        border: 1px solid #888;
        border-radius: 0.5em;
        width: 60%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 600px !important;
    }

      .only-desktop {
        display: block;
      }
}

@media screen and (min-width: 1000px) {
    .c-hero__bg-md {
        background-size: 140%;
        background-position: top center;
    }
    .c-hero__wrapper .c-hero__columns {
        gap: 90px;
    }
}

/* Hero Footer */

.c-hero-footer {
    position: relative;
    background: url(/_next/static/media/wave-hero-footer-sm.727d79c1.svg) no-repeat center / cover;
    padding: 60px 0 30px;
    margin-top: -65px;
    color: white;
}

.c-hero-footer__heading {
    font-size: 0.875rem;
    text-transform: uppercase;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .c-hero-footer {
        background-image: url(/_next/static/media/wave-hero-footer.c0d57bd0.svg);
        padding: 45px 0;
        margin-top: -50px;
    }
    .c-hero-footer__heading {
        font-size: 1.125rem;
        font-weight: 600;
    }
}

@media (min-width: 768px) {
  .c-about-video__container {
    height: 700px;
  }
}

.c-about-video__animation-container {
  height: 281px;
  width: 281px;
}

@media (min-width: 768px) {
  .c-about-video__animation-container {
    height: 972px;
    width: 972px;
  }
}

@keyframes ring-animation {
  100% {
    transform: rotate(360deg);
  }
}

.c-about-video__rings {
  animation: ring-animation 60s linear infinite;
}

.c-about-video__copy {
  max-width: 312px;
}

.c-about-video__lens {
  height: 83px;
  width: 132px;
}

@media (min-width: 768px) {
  .c-about-video__lens {
    height: 198px;
    width: 316px;
  }
}

.c-about-video__play-button {
  height: 46px;
  width: 46px;
}

@media (min-width: 768px) {
  .c-about-video__play-button {
    height: 96px;
    width: 96px;
  }
}

.c-background-image-copy__container {
  min-height: calc(672 / 812 * 100vh);
}

@media (min-width: 1024px) {

.c-background-image-copy__container {
    min-height: calc(700 / 900 * 100vh);
}
  }

.c-background-image-copy__block--body-align-inline_start .c-background-image-copy__background-image-fade,
.c-background-image-copy__block--body-align-inline_start .c-background-image-copy__background-image {
    -o-object-position: 60% 50%;
       object-position: 60% 50%;
}

@media (min-width: 768px) {

.c-background-image-copy__block--body-align-inline_start .c-background-image-copy__background-image-fade,
.c-background-image-copy__block--body-align-inline_start .c-background-image-copy__background-image {
      -o-object-position: 50% 50%;
         object-position: 50% 50%;
}
    }

.c-background-image-copy__block--body-align-inline_end .c-background-image-copy__background-image-fade,
.c-background-image-copy__block--body-align-inline_end .c-background-image-copy__background-image {
    -o-object-position: 40% 50%;
       object-position: 40% 50%;
}

@media (min-width: 768px) {

.c-background-image-copy__block--body-align-inline_end .c-background-image-copy__background-image-fade,
.c-background-image-copy__block--body-align-inline_end .c-background-image-copy__background-image {
      -o-object-position: 50% 50%;
         object-position: 50% 50%;
}

.c-background-image-copy__block--body-align-block_start .c-background-image-copy__background-image-fade,
.c-background-image-copy__block--body-align-block_start .c-background-image-copy__background-image {
      -o-object-position: 50% 75%;
         object-position: 50% 75%;
}

.c-background-image-copy__block--body-align-block_end .c-background-image-copy__background-image-fade,
.c-background-image-copy__block--body-align-block_end .c-background-image-copy__background-image {
      -o-object-position: 50% 25%;
         object-position: 50% 25%;
}
    }

.c-background-image-copy__body {
  max-width: 358px;
}

.c-centered-copy__container {
  max-width: calc(936px + 40px);
}

.c-centered-copy__copy a,
.c-centered-copy__notes a {
  --tw-text-opacity: 1;
  color: rgb(16 168 186 / var(--tw-text-opacity));
}

div.anchor {
  display: block;
  position: relative;
  top: -140px;
  visibility: hidden;
}

.c-comparison-table {
  max-width: 1168px;
  margin-left: auto;
  margin-right: auto;
}

.c-comparison-table__row-headers {
  display: none;
}

@media (min-width: 640px) {

.c-comparison-table__row-headers {
    display: flex;
}
  }

.c-comparison-table__row:nth-child(2) .c-comparison-table__row-headers {
  display: flex;
}

.c-comparison-table .a-svg {
  margin-left: auto;
  margin-right: auto;
}

.c-comparison-table__footer-p {
  width: 10rem;
  font-weight: 600;

  font-size: 14px;
}

.c-comparison-table__row:last-child {
  border-bottom: 0;
}

.c-comparison-table__row-heading {
  line-height: 18px !important;
  padding-bottom: 13px;
  padding-top: 13px;
}

.c-comparison-table__row-svg-cont {
  padding-bottom: 13px;
  padding-top: 13px;
}

@media (min-width: 640px) {

.c-comparison-table__row-svg-cont {
    padding-bottom: 16px;
    padding-top: 10px;
}
  }

.c-comparison-table_custom-stick {
  position: sticky;
  top: 72px;
}

@media (min-width: 640px) {

.c-comparison-table_custom-stick {
    display: none;
}

    .c-comparison-table_custom-stick.flex {
      display: flex;
    }
  }

.c-counter__body {
  max-width: 358px;
}

.c-counter__heading,
.c-counter__copy,
.c-counter__button,
.c-counter__link {
  opacity: 0;
}

.c-counter__button,
.c-counter__link {
  transform: translateY(20px);
}

.c-counter__button {
  display: none !important;
}

.c-counter__link {
  transition: 3s 1s;
}

[dir="rtl"] .c-counter__link-arrow {
    transform: rotate(180deg);
}

.c-counter__animation {
  padding: 40px;
}

@media (min-width: 1024px) {
  .c-counter__button {
    display: inline-block !important;
  }

  .c-counter__animation {
    padding: 0;
  }
}

.c-counter__container.is-active .c-counter__heading,
.c-counter__container.is-active .c-counter__copy,
.c-counter__container.is-active .c-counter__button,
.c-counter__container.is-active .c-counter__link {
  opacity: 1;
}

.c-counter__container.is-active .c-counter__button,
.c-counter__container.is-active .c-counter__link {
  transform: translateY(0);
}

.c-content-with-side-image__wrapper {
    position: relative;
    background: no-repeat top center / cover;
    z-index: 1;
    padding: 70px 24px;
}

.c-content-with-side-image__wrapper::after {
    content: '';
    background: no-repeat top center / cover;
    position: absolute;
    top: 8px;
    bottom: 20px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-content-with-side-image__default {
    background-image: url(/_next/static/media/wave-content-bottom.d6157c9e.svg);
}

.c-content-with-side-image__default::after {
    background-image: url(/_next/static/media/wave-content-top.92f66026.svg);
}

.c-content-with-side-image__dark {
    background-image: url(/_next/static/media/wave-content-bottom-dark-sm.df070310.svg);
    color: white;
}

.c-content-with-side-image__dark::after {
    background-image: url(/_next/static/media/wave-content-top-dark-sm.72f47d07.svg);
}

.c-content-with-side-image__content h5 {
    font-weight: 600;
    font-size: 1rem;
    color: #10A8BA
}

.c-content-with-side-image__content ul {
    margin-top: 24px;
    font-size: 1.125rem;
}

.c-content-with-side-image__content ul > li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.c-content-with-side-image__content ul > li::before {
    content: url(/_next/static/media/orange-check.0e02d246.svg);
    display: inline-block;
    width: 35px;
    height: 35px;
    margin-right: 20px;
    min-width: 35px;
}

.m-image-wrapper > img, .m-image-wrapper > video {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}

@media screen and (min-width: 768px) {
    .c-content-with-side-image__default {
        padding: 9vw 80px 7vw;
        background-image: url(/_next/static/media/wave-content-bottom.d6157c9e.svg);
    }

    .c-content-with-side-image__default::after {
        background-image: url(/_next/static/media/wave-content-top.92f66026.svg);
    }

    .c-content-with-side-image__dark {
        padding: 130px 80px;
        background-image: url(/_next/static/media/wave-content-bottom-dark.fc718602.svg);
    }

    .c-content-with-side-image__dark::after {
        background-image: url(/_next/static/media/wave-content-top-dark.f3d668b5.svg);
    }
}

@media screen and (max-width: 768px) {
    .c-content-with-image {
        margin-bottom: 15px;
    }
}

.c-cta-box__link {
  height: 354px;
}
@media (min-width: 768px) {
  .c-cta-box__link {
    height: 508px;
}
  }

.c-cta-contact {
    position: relative;
    background: url(/_next/static/media/wave-contact-bottom.5c8d0c92.svg) no-repeat center / cover;
    z-index: 1;
    padding: 110px 0;
}

.c-cta-contact::after {
    content: '';
    background: url(/_next/static/media/wave-contact-top.838c3492.svg) no-repeat center / cover;
    position: absolute;
    top: 0;
    bottom: 20px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-cta-contact__testimonial-blockquote > span {
    font-size: 63px;
    line-height: 0;
    position: relative;
    top: 10px;
}

@media screen and (min-width: 768px) {
    .c-cta-contact {
        background-image: url(/_next/static/media/wave-contact-bottom.5c8d0c92.svg);
        padding: 10vw 0;
        margin-top: -50px;
    }

    .c-cta-contact::after {
        background-image: url(/_next/static/media/wave-contact-top.838c3492.svg);
    }
}

.c-video-slider__container {
  max-width: 1128px;
  padding: 0 20px;
  
}

.c-video-slider__container .glide__slides {
  align-items: center;
}

@media (max-width: 1024px) {
  .dots-below {
    position: absolute;
   bottom: -90px;
  }
}


  @media (min-width: 1024px) {

.c-faq-slider {
    background: linear-gradient(to left, transparent 0%, transparent 50%, #73CCD7 50%);
}

[dir="rtl"] .c-faq-slider {
      background: linear-gradient(to right, transparent 0%, transparent 50%, #73CCD7 50%);
}
  }

.c-faq-slider__container {
  max-width: 1128px;
}

@media (min-width: 1024px) {

.c-faq-slider__container {
    background: linear-gradient(to left, transparent 0%, transparent 30%, #73CCD7 30%);
    min-height: calc(700 / 900 * 100vh);
}

[dir="rtl"] .c-faq-slider__container {
      background: linear-gradient(to right, transparent 0%, transparent 30%, #73CCD7 30%);
}
  }

.c-faq-slider__heading {
  max-width: 452px;
}

@media (min-width: 1024px) {

.c-faq-slider__heading {
    max-width: 500px;
}
  }

.c-faq-slider__slide-body {
  min-height: calc(330 / 812 * 100vh);
}

@media (min-width: 1024px) {

.c-faq-slider__slide-body {
    min-height: auto;
}
  }

.c-faq-slider__slide-content {
  max-width: 487px;
}

.c-faq-slider__slide-attribution {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

@media (min-width: 640px) {

.c-faq-slider__slide-attribution {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
}
  }

.c-faq-slider__pagination-dots {
  max-width: 487px;
}

@media (min-width: 1024px) {

.c-faq-slider__pagination-dots {
    max-width: 564px;
}
  }
.c-find-a-doctor__background-image {
  -o-object-position: 75% 50%;
     object-position: 75% 50%;
}

@media (min-width: 768px) {

.c-find-a-doctor__background-image {
    -o-object-position: 50% 50%;
       object-position: 50% 50%;
}
  }

.c-find-a-doctor__container {
  min-height: calc(548 / 812 * 100vh);
}

@media (min-width: 1024px) {

.c-find-a-doctor__container {
    min-height: calc(558 / 900 * 100vh);
}
  }

.c-find-a-doctor__body {
  max-width: 621px;
}

.c-find-a-doctor__search-box {
  max-width: 813px;
}

.c-find-a-doctor__regions-box {
  max-width: 551px;
}

.c-find-a-doctor__additional-copy {
  font-size: 12px;
}

.c-image-copy-blocks__tile {
  min-height: calc(330 / 812 * 100vh);
}

@media (min-width: 1024px) {

.c-image-copy-blocks__tile {
    min-height: calc(700 / 900 * 100vh);
}
  }

.c-image-copy-blocks__tile--media {
  -webkit-clip-path: inset(100% 0 0 0);
          clip-path: inset(100% 0 0 0);
  transition: -webkit-clip-path 0.5s 0.3s;
  transition: clip-path 0.5s 0.3s;
  transition: clip-path 0.5s 0.3s, -webkit-clip-path 0.5s 0.3s;
}

.c-image-copy-blocks__block.is-active-down .c-image-copy-blocks__tile--media {
    -webkit-clip-path: inset(0);
            clip-path: inset(0);
    transition: -webkit-clip-path 0.5s;
    transition: clip-path 0.5s;
    transition: clip-path 0.5s, -webkit-clip-path 0.5s;
}

.c-image-copy-blocks__background-image,
.c-image-copy-blocks__background-video {
  -webkit-clip-path: inset(100% 0 0 0);
          clip-path: inset(100% 0 0 0);
  transition: -webkit-clip-path 0.5s;
  transition: clip-path 0.5s;
  transition: clip-path 0.5s, -webkit-clip-path 0.5s;
}

.c-image-copy-blocks__block.is-active-down .c-image-copy-blocks__background-image,
.c-image-copy-blocks__block.is-active-down .c-image-copy-blocks__background-video {
    -webkit-clip-path: inset(0);
            clip-path: inset(0);
    transition: -webkit-clip-path 0.5s 0.3s;
    transition: clip-path 0.5s 0.3s;
    transition: clip-path 0.5s 0.3s, -webkit-clip-path 0.5s 0.3s;
}

.c-image-copy-blocks__body {
  max-width: 358px;
}

.c-split-testimonial__content {
  max-width: 622px;
}

.c-split-testimonial__image-container {
  -webkit-clip-path: inset(100% 0 0 0);
          clip-path: inset(100% 0 0 0);
  transition: -webkit-clip-path 0.5s ease-out;
  transition: clip-path 0.5s ease-out;
  transition: clip-path 0.5s ease-out, -webkit-clip-path 0.5s ease-out;
}

.c-split-testimonial--animate-down .c-split-testimonial__image-container {
    -webkit-clip-path: inset(0);
            clip-path: inset(0);
}

.c-split-testimonial__image-container-horizontal {
  height: 330px;
  width: 100%;
}

@media (min-width: 1024px) {

.c-split-testimonial__image-container-horizontal {
    height: 668px;
    width: 38%;
}
  }

.c-split-testimonial__image-container-vertical {
  height: 518px;
  width: 100%;
}

@media (min-width: 1024px) {

.c-split-testimonial__image-container-vertical {
    height: 668px;
    width: 28%;
}
  }

.c-split-testimonial__quote {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.c-split-testimonial--animate .c-split-testimonial__quote {
    opacity: 1;
}

.c-split-testimonial__button {
  opacity: 0;
  transform: translate3d(0, 32px, 0);
  transition: all transform 0.5s ease-out 0.2s, opacity 0.5s ease-out 0.2s;
  background-color: transparent;
  color: inherit;
}

.c-split-testimonial--animate .c-split-testimonial__button {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.c-stats__container {
  max-width: 1440px;
  min-height: calc(672 / 812 * 100vh);
}

@media (min-width: 1024px) {

.c-stats__container {
    min-height: calc(700 / 900 * 100vh);
}
  }

.c-stats__stat:nth-child(n+2) {
  border-top-width: 1px;
}

@media (min-width: 1024px) {

.c-stats__stat:nth-child(n+2) {
    border-top-width: 0px;
  }

.c-stats__stat:nth-child(even) {
    border-inline-start-width: 1px;
  }
  }

.c-stats__number {
  font-size: 64px;
  font-weight: 300;
  line-height: 1.25;
}

@media (min-width: 1024px) {

.c-stats__number {
    font-size: 96px;
    font-weight: normal;
    line-height: 0.92;
}
  }

.c-stats__badge {
  max-width: 333px;
}

.c-stats__badge strong,
.c-stats__badge em {
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(16 168 186 / var(--tw-text-opacity));
}

.c-hero-footer__heading p {
  font-weight: 500;
}

.c-hero-footer__heading strong {
  font-weight: 700;
}


  @media (min-width: 1024px) {

.c-testimonials-slider {
    background: linear-gradient(to right, transparent 0%, transparent 50%, #FCB281 50%);
}

[dir="rtl"] .c-testimonials-slider {
      background: linear-gradient(to left, transparent 0%, transparent 50%, #FCB281 50%);
}
  }

.c-testimonials-slider__container {
  max-width: 1128px;
}

@media (min-width: 1024px) {

.c-testimonials-slider__container {
    background: linear-gradient(to right, transparent 0%, transparent 30%, #FCB281 30%);
    min-height: calc(700 / 900 * 100vh);
}

[dir="rtl"] .c-testimonials-slider__container {
      background: linear-gradient(to left, transparent 0%, transparent 30%, #FCB281 30%);
}
  }

.c-testimonials-slider__heading {
  max-width: 452px;
}

.c-testimonials-slider__testimonial-body {
  min-height: calc(330 / 812 * 100vh);
}

@media (min-width: 1024px) {

.c-testimonials-slider__testimonial-body {
    min-height: auto;
}
  }

.c-testimonials-slider-us .glide__slides {
  align-items: start;
}

.c-testimonials-slider__testimonial-content {
  max-width: 487px;
}

.c-testimonials-slider__testimonial-blockquote::before {
    color: #de742e;
    content: "“";
  }

.c-testimonials-slider__testimonial-blockquote::after {
    color: #de742e;
    content: "”";
  }

.c-testimonials-slider__testimonial-attribution {
  color: #de742e;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

@media (min-width: 640px) {

.c-testimonials-slider__testimonial-attribution {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
}
  }

.c-testimonials-slider__testimonial-attribution::before {
    content: "- ";
  }

.c-testimonials-slider__pagination-dots {
  max-width: 487px;
}

@media (min-width: 1024px) {

.c-testimonials-slider__pagination-dots {
    max-width: 564px;
}
  }

.c-testimonials-slider-us__container {
    max-width: 1228px;
}

.c-testimonials-slider-us {
    position: relative;
    background: url(/_next/static/media/wave-testimonials-bottom.ef0aebd1.svg) no-repeat center / cover;
    z-index: 1;
    padding: 130px 0;
}

.c-testimonials-slider-us::after {
    content: '';
    background: url(/_next/static/media/wave-testimonials-top.94a15d17.svg) no-repeat center / cover;
    position: absolute;
    top: 8px;
    bottom: 20px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-testimonials-slider-us__testimonial-blockquote > span {
    font-size: 63px;
    line-height: 0;
    position: relative;
    top: 10px;
}

.c-testimonials-slider-us__testimonial > div.relative > div > div {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.c-testimonials-slider-us__testimonial-blockquote {
    position: relative;
    padding: 0 20px;
}

.c-testimonials-slider-us__testimonial-blockquote > span:first-child {
    position: absolute;
    top: 35px;
    left: -10px;
    color: #10a8ba;
}

.c-testimonials-slider-us__testimonial-blockquote > span:last-child {
    top: 20px;
    left: 5px;
    color: #10a8ba;
}

@media screen and (max-width: 768px) {
    .c-testimonials-slider-us__disable-border-mobile.c-testimonials-slider-us, .c-testimonials-slider-us__disable-border-mobile.c-testimonials-slider-us::after {
        background: none;
        padding: 30px 0;
    }
}

@media screen and (min-width: 768px) {
    .c-testimonials-slider-us, .c-testimonials-slider-us::after {
        background-position: top center;
    }
}

@media screen and (min-width: 1024px) {
    .c-testimonials-slider-us__container .glide--slider::before, .c-testimonials-slider-us__container .glide--slider::after {
        content: '';
        position: absolute;
        opacity: 0.8;
        width: 120px;
        height: 100%;
        top: 0;
        z-index: 2;
    }

    .c-testimonials-slider-us__container .glide--slider::before {
        left: -1px;
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%);
    }

    .c-testimonials-slider-us__container .glide--slider::after {
        right: -1px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }
}

.c-404 {
  height: calc(100vh - 72px);
  margin-top: 72px;
  padding: 0 32px;
}

.c-404__heading {
  font-size: 160px;
  line-height: 160px;
}

.c-404__subheading {
  max-width: 835px;
}

@media (min-width: 768px) {
  .c-404 {
    height: calc(100vh - 106px);
    margin-top: 106px;
  }
}

.c-500__body {
  background-image: url("/img/bg-500-mobile.jpg");
}

@media (min-width: 768px) {

.c-500__body {
    background-image: url("/img/bg-500.jpg");
}
  }

.c-500__content {
  max-width: 924px;
}

.c-500__logo-bar {
  height: 72px;
}

.c-500__logo {
  height: 30px;
  width: 100px;
}

.c-full-width-testimonial {
  height: 675px;
}

.c-full-width-testimonial__quote {
  max-width: 760px;
}

.c-full-width-testimonial__content {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.c-full-width-testimonial--animate .c-full-width-testimonial__content {
    opacity: 1;
}

.c-full-width-testimonial__button {
  transform: translate3d(0, 32px, 0);
}

.c-full-width-testimonial--animate .c-full-width-testimonial__button {
    transform: translate3d(0, 0, 0);
}
.c-small-testimonial-grid__item {
  margin-top: 0.75rem;
  width: 100%;
}

.c-small-testimonial-grid__item--full-width {
  width: 100%;
}

@media (min-width: 1024px) {
  .c-small-testimonial-grid__item {
    width: calc(50% - (0.75rem * 2));
  }

  .c-small-testimonial-grid__item--full-width {
    width: calc(100% - (0.75rem * 2));
  }
}


  @media (min-width: 1024px) {

.c-image-copy-slides {
    --m-pinned-subnav-height: 72px;
}
  }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

.c-image-copy-slides__main--image-align-inline_start {
    flex-direction: row-reverse !important;
}

.c-image-copy-slides__main--image-align-inline_end {
    flex-direction: row !important;
}
  }

.c-image-copy-slides__blocks--fade {
  height: calc(0.5 * (100vh - 2 * 0.75rem - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)));
  top: calc(0.75rem + var(--c-the-header-height, 72px));
}

@media (min-width: 768px) {

.c-image-copy-slides__blocks--fade {
    height: calc(0.5 * (100vh - 2 * 1.5rem - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)));
    top: calc(1.5rem + var(--c-the-header-height, 72px));
}
  }

@media (min-width: 1024px) {

.c-image-copy-slides__blocks--fade {
    height: calc(100vh - 2 * 1.5rem - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px));
}
  }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

.c-image-copy-slides__blocks--fade {
    inset-inline-end: 0;
    position: absolute !important;
}

.c-image-copy-slides.is-active .c-image-copy-slides__blocks--fade {
      position: fixed !important;
}

.c-image-copy-slides__block--fade {
    opacity: 1 !important;
    pointer-events: auto !important;
    position: relative !important;
}
  }

.c-image-copy-slides__block--normal {
  height: calc(100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px));
  margin-top: calc(-0.5 * (100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)));
  padding-top: calc(0.5 * (100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)) + 0.75rem);
}

@media (min-width: 768px) {

.c-image-copy-slides__block--normal {
    margin-top: calc(-0.5 * (100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)));
    padding-top: calc(0.5 * (100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)) + 1.5rem);
}
  }

@media (min-width: 1024px) {

.c-image-copy-slides__block--normal {
    margin-top: 0;
    padding-top: 0;
}
  }

.c-image-copy-slides__body {
  max-width: 344px;
}

@media (min-width: 1024px) {
  .c-background-image-list__container {
    height: 700px;
  }
}

.c-background-image-list__image-ratio {
  padding-top: 100%;
  width: 100%;
}

@media (min-width: 640px) {
  .c-background-image-list__image-ratio {
    padding-top: 0;
  }
}

.c-background-image-list__inner-container {
  max-width: calc(1128px + 3rem);
}

.c-background-image-list__item {
  display: flex;
  align-items: flex-start;
  --tw-text-opacity: 1;
  color: rgb(144 148 149 / var(--tw-text-opacity));
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;

  margin-top: 1rem;
}

@media (min-width: 768px) {
  .c-background-image-list__item {
    align-items: center;
  }
}

@media (min-width: 1024px) {
  .c-background-image-list__item {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.c-background-image-list__item:first-child {
  margin-top: 0;
}

.c-background-image-list__dot {
  --tw-bg-opacity: 1;
  background-color: rgb(16 168 186 / var(--tw-bg-opacity));
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex-shrink: 0;
}

@media (min-width: 1024px) {
  .c-background-image-list__dot {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(16 168 186 / var(--tw-text-opacity));
    width: 2rem;
    height: 2rem;
  }
}

.c-background-image-list__dot svg {
  width: 17px;
}

.c-background-image-list__heading {
  max-width: 385px;
}

.c-background-image-list__subheading {
  max-width: 385px;
}

.c-background-image-list__copy-box {
  max-width: 520px;
}

.c-background-image-list__copy {
  font-size: 12px;
}

.c-partial-text-masthead__spacer {
  height: 76px;
}

@media (min-width: 768px) {
  .c-partial-text-masthead__spacer {
    height: 106px;
  }
}

@media (min-width: 1280px) {
  .c-partial-text-masthead__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 473px;
    width: calc(50% - 250px);
  }

  .c-partial-text-masthead__right {
    bottom: 0;
    inset-inline-end: 0;
    position: absolute;
    top: 0;
    width: calc(50vw + 219px);
  }
}
.c-expandable-faqs__item {
  border-bottom: 1px solid #c4c4c4;
}

.c-expandable-faqs__plus {
  position: relative;

  height: 21px;
  width: 21px;
}

.c-expandable-faqs__plus-bar {
  position: absolute;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(16 168 186 / var(--tw-bg-opacity));

  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.c-expandable-faqs__plus-bar--vertical {
  transform: translateY(-50%) rotate(90deg);
  transition: transform 0.1s ease-out;
}

.c-expandable-faqs__item--expanded .c-expandable-faqs__plus-bar--vertical {
  transform: translateY(-50%) rotate(0);
}

.c-expandable-faqs__answer {
  height: 0;
  max-width: 700px;
  overflow: hidden;
  transition: height 0.2s ease-out;
}

.c-expandable-faqs__answer::before {
  content: "";
  display: block;
  height: 1rem;
  width: 100%;
}

.c-expandable-faqs-us__item {
    border-bottom: 1px solid #D9DEDF;
}

.c-expandable-faqs-us__plus {
    position: relative;

    height: 21px;
    width: 21px;
}

.c-expandable-faqs-us__icon {
    transform: rotate(180deg);
    transition: all ease-in-out 0.3s;
}

.c-expandable-faqs-us__item--expanded .c-expandable-faqs-us__icon {
    transform: rotate(0);
}

.c-expandable-faqs-us__answer {
    height: 0;
    max-width: 95%;
    overflow: hidden;
    transition: height 0.2s ease-out;
}

.c-expandable-faqs-us__answer::before {
    content: "";
    display: block;
    height: 1rem;
    width: 100%;
}

@media (max-width: 767px) {
    .c-expandable-faqs-us {
        margin-top: 0;
    }
}

.c-expandable-faqs-evo__item {
    margin-bottom: 15px;
}

.c-expandable-faqs-evo__item button {
    background: white;
    border-radius: 10px;
    padding: 15px 25px 15px 30px;
}

.c-expandable-faqs-evo__icon {
    transform: rotate(180deg);
    transition: all ease-in-out 0.3s;
}

.c-expandable-faqs-evo__item--expanded .c-expandable-faqs-evo__icon {
    transform: rotate(0);
}

.c-expandable-faqs-evo__item--expanded .c-expandable-faqs-evo__answer {
    margin-top: 25px;
}

.c-expandable-faqs-evo__answer {
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
    padding: 0 30px;
    max-width: 95%;
}

@media (max-width: 767px) {
    .c-expandable-faqs-evo {
        margin-top: 0;
    }
}

.c-minor-image-masthead__spacer {
  height: 76px;
}

@media (min-width: 768px) {
  .c-minor-image-masthead__spacer {
    height: 106px;
  }
}
.c-copy-block {
  --h-color: #57606C;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  --tw-text-opacity: 1;
  color: rgb(144 148 149 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .c-copy-block {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
}

.c-testimonial-masthead__spacer {
  height: 76px;
}

@media (min-width: 768px) {
  .c-testimonial-masthead__spacer {
    height: 106px;
  }
}

.c-testimonial-masthead__heading-column {
  max-width: 505px;
}

.c-testimonial-masthead__copy {
  max-width: 653px;
}

.c-testimonial-masthead__image {
  height: 330px;
}

@media (min-width: 768px) {
  .c-testimonial-masthead__image {
    height: 556px;
  }
}

.c-testimonial-masthead__expand-button {
  border-bottom: 1px solid rgba(255 255 255 / 20%);
  height: 64px;
}

.c-testimonial-masthead__scroll-button {
  padding: 0;
}

.c-testimonial-masthead__plus {
  position: relative;

  height: 21px;
  width: 21px;
}

.c-testimonial-masthead__plus-bar {
  position: absolute;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));

  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.c-testimonial-masthead__plus-bar--vertical {
  transform: translateY(-50%) rotate(90deg);
  transition: transform 0.1s ease-out;
}

.c-testimonial-masthead__info--expanded .c-testimonial-masthead__plus-bar--vertical {
  transform: translateY(-50%) rotate(0);
}

.c-testimonial-masthead__info {
  transition: height 0.2s ease-out;
}
.c-simple-copy__container {
  margin: 0 auto;
  max-width: calc(808px - 0.75rem * 2);
}

@media (min-width: 768px) {
  .c-simple-copy__container {
    max-width: calc(808px - 1.5rem * 2);
  }
}
.c-full-width-video__poster {
  transform: scale(1.1);
  transition: transform 0.4s ease-out;
}

.c-full-width-video--animate .c-full-width-video__poster {
  transform: scale(1);
}

.c-full-width-video__button {
  border-radius: 9999px;
}
.c-story-gallery__slider {
  height: 291px;
}

.c-story-gallery__slides {
  -webkit-clip-path: inset(100% 0 0 0);
          clip-path: inset(100% 0 0 0);
  transition: -webkit-clip-path 0.5s 0.3s;
  transition: clip-path 0.5s 0.3s;
  transition: clip-path 0.5s 0.3s, -webkit-clip-path 0.5s 0.3s;
}

.c-story-gallery--animate-down .c-story-gallery__slides {
  -webkit-clip-path: inset(0);
          clip-path: inset(0);
}

.c-story-gallery__background {
  -webkit-clip-path: inset(100% 0 0 0);
          clip-path: inset(100% 0 0 0);
  transition: -webkit-clip-path 0.5s;
  transition: clip-path 0.5s;
  transition: clip-path 0.5s, -webkit-clip-path 0.5s;
}

.c-story-gallery--animate-down .c-story-gallery__background {
  -webkit-clip-path: inset(0);
          clip-path: inset(0);
}

.c-story-gallery__copies {
  height: 300px;
  transition: height 0.2s ease-out;
}

.c-story-gallery__heading {
  opacity: 0;
  transition: opacity 0.5s;
}

.c-story-gallery--animate .c-story-gallery__heading {
  opacity: 1;
}

.c-story-gallery__copies-container {
  opacity: 0;
  transition: opacity 0.5s 0.2s;
}

.c-story-gallery--animate .c-story-gallery__copies-container {
  opacity: 1;
}

@media (min-width: 1024px) {
  .c-story-gallery__copies {
    height: auto;
  }

  .c-story-gallery {
    height: 700px;
  }

  .c-story-gallery__content {
    width: calc(50% - 0.75rem);
  }

  .c-story-gallery__slider {
    height: auto;
    width: calc(50% - 0.75rem);
  }
}

.c-story-gallery__copy {
  opacity: 0;
  transition: opacity 0.2s ease-out;
  transition-delay: 0s;
}

.c-story-gallery__copy--active {
  opacity: 1;
  transition-delay: 0.2s;
}

[dir="rtl"] .c-story-gallery__controls-arrow-icon {
    transform: rotate(180deg);
}

.c-image-section-break__image {
  height: 454px;
  transform: scale(1.1);
  transition: transform 0.4s ease-out;
}

@media (min-width: 768px) {
  .c-image-section-break__image {
    height: 700px;
  }
}

.c-image-section-break--animate .c-image-section-break__image {
  transform: scale(1);
}
.c-questions-and-answers__heading {
  max-width: 340px;
  opacity: 0;
  transform: translate3d(0, 32px, 0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out 0.1s;
}

.c-questions-and-answers--animate .c-questions-and-answers__heading {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.c-questions-and-answers__qas {
  opacity: 0;
  transform: translate3d(0, 32px, 0);
  transition: opacity 0.3s ease-out 0.2s, transform 0.3s ease-out 0.4s;
}

.c-questions-and-answers--animate .c-questions-and-answers__qas {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.c-questions-and-answers__item {
  margin-top: 3.5rem;
}

@media (min-width: 768px) {
  .c-questions-and-answers__item {
    margin-top: 5rem;
  }
}

.c-questions-and-answers__item:first-child {
  margin-top: 0;
}

.c-photo-collage__image-container {

  position: relative;

  margin-top: 1rem;
  width: 100%;
}

.c-photo-collage__ratio {

  position: relative;

  height: 0;
  padding-top: 100%;
}

@media (min-width: 640px) {
  .c-photo-collage__image-container {
    margin-top: 0;
    width: 33.3333%;
  }

  .c-photo-collage__image-container--double {
    width: 66.6666%;
  }

  .c-photo-collage__image-container--double .c-photo-collage__ratio {
    padding-top: 50%;
  }
}

.c-photo-collage__image {

  position: absolute;

  top: 0px;

  left: 0px;

  height: 100%;

  width: 100%;

  -o-object-fit: cover;

     object-fit: cover;
}

@media (min-width: 768px) {
  .c-related-articles__item {
    margin: 10px;
    width: calc(33.3333% - 20px);
  }
}

.c-locale-switcher-screen__logo {
  width: 195px;
  height: 19px;
}

.c-locale-switcher-screen__background {
  z-index: 1;
}

.c-locale-switcher-screen__background-ellipses {
  bottom: -92px;
  left: -185px;
  width: 644px;
}

@media (min-width: 768px) {
  .c-locale-switcher-screen__background-image {
    max-width: 60%;
  }
}

.c-locale-switcher-screen__background-gradient {
  left: 0;
  top: 20%;
  width: 100%;
}

@media (min-width: 768px) {
  .c-locale-switcher-screen__background-gradient {
    left: 40%;
    top: initial;
    width: 30%;
  }
}

.c-article-listing__trending-item {
  margin-top: 3.5rem;
  width: calc(100%);
}

.c-article-listing__trending-item:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .c-article-listing__trending {
    margin: 0 -10px;
  }

  .c-article-listing__trending-item {
    margin: 0 10px;
    width: calc(50% - 20px);
  }
}

.c-article-listing__articles {
  margin: 10px -10px -10px;
}

@media (min-width: 640px) {
  .c-article-listing__articles {
    margin: 94px -10px -10px;
  }
}

.c-article-listing__articles-item {
  margin: 10px;
  width: calc(100% - 20px);
}

@media (min-width: 640px) {
  .c-article-listing__articles-item {
    width: calc(50% - 20px);
  }
}

@media (min-width: 1024px) {
  .c-article-listing__articles-item {
    width: calc(33.3333% - 20px);
  }
}

.c-article-masthead {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

@media (min-width: 768px) {
  .c-article-masthead {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
}

.c-article-masthead__picture-container {
  --container-padding-inline: 0;
  --container-max-width: var(--container-lg-client-max-width);
}
.c-kr-newsletter__background-image {
  -o-object-position: 75% 50%;
     object-position: 75% 50%;
}

@media (min-width: 768px) {

.c-kr-newsletter__background-image {
    -o-object-position: 50% 50%;
       object-position: 50% 50%;
}
  }

.c-kr-newsletter__body {
  max-width: 621px;
}

.c-kr-newsletter__search-box {
  max-width: 813px;
}

.c-kr-newsletter__regions-box {
  max-width: 551px;
}
.c-wtw-slides__slide {
  background-color: #f5fbf8;
}

@media (min-width: 768px) {
  .c-wtw-slides__slide {
    height: 750px;
  }
}

.c-wtw-slides__slide-container {
  max-width: 1440px;
}

.c-wtw-slides__slide-copy {
  max-width: 360px;
}

.c-legacy-navigation-evo__bar {
  height: 2px;
  left: 0;
  top: 50%;
}

.c-legacy-navigation-evo__bar--top {
  transform: translateY(calc(-9px - 50%));
}

.c-legacy-navigation-evo__bar--middle {
  transform: translateY(-50%);
}

.c-legacy-navigation-evo__bar--bottom {
  transform: translateY(calc(9px - 50%));
}

.active .c-legacy-navigation-evo__bar--top {
  transform: translateY(-50%) rotate(45deg);
}

.active .c-legacy-navigation-evo__bar--middle {
  opacity: 0;
}

.active .c-legacy-navigation-evo__bar--bottom {
  transform: translateY(-50%) rotate(-45deg);
}

.c-legacy-menu-icl__content {
  background-color: rgba(0, 0, 0, 0.8);
}

.c-legacy-menu-icl__bar {
  background-color: #57606c;
}

.c-legacy-menu-icl__item {
  background-color: #57606c;
}

.c-legacy-menu-icl__item--primary {
  background-color: #71ccd7;
}

.c-legacy-menu-icl__item--secondary {
  background-color: #3f454f;
}

.c-legacy-menu-icl__link,
.c-legacy-menu-icl__sublink {
  cursor: pointer;
  font-size: 13px;
  letter-spacing: 0.78px;
  line-height: 22px;
}

.c-legacy-menu-icl__subitem {
  background-color: #3f454f;
}

.c-legacy-menu-icl__socials-list {
  background-color: #3f454f;
}

.c-legacy-menu-icl__socials-link {
  color: #909495;
}

.c-legacy-menu-icl__dropdown-search {
  background-color: #57606c;
  font-size: 13px;
}

.c-legacy-menu-icl__dropdown-search-button {
  font-size: 12px;
}

.c-legacy-menu-icl__dropdown-search-button-icon {
  height: 14px;
  width: 14px;
}

.c-legacy-menu-icl__dropdown-search-input {
  height: 34px;
}

.c-legacy-menu-icl__dropdown-search-input-button {
  height: 34px;
}

.c-legacy-menu-icl__dropdown-search-icon {
  width: 16px;
  height: 16px;
}

.c-legacy-menu-icl__dropdown-heading {
  color: #57606c;
}

.c-legacy-menu-icl__dropdown-menus {
  border-bottom: 1px solid #d9dedf;
}

.c-legacy-menu-icl__dropdown-menu-item {
  font-size: 13px;
  padding-top: 7px;
}

.c-legacy-menu-icl__dropdown-menu {
  margin-top: 48px;
}

.c-legacy-menu-icl__dropdown-menu:first-child {
  margin-top: 0;
}

.c-legacy-menu-icl__dropdown-menu-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 30px;
  background-color: #10a8ba;
}

.c-legacy-menu-icl__dropdown-submenu-item {
  font-size: 12px;
  color: #909495;
  line-height: 34px;
}

.c-legacy-menu-icl__dropdown-submenu-item:hover {
  color: #10a8ba;
}

.c-legacy-menu-icl__dropdown-column {
  width: 240px;

}

.c-legacy-menu-icl__dropdown-column:nth-child(2) {
  margin: 0 2.5rem;
}

.c-legacy-menu-icl__dropdown-more-links {
  -moz-columns: 3;
       columns: 3;
  -moz-column-gap: 2.5rem;
       column-gap: 2.5rem;
}

.c-legacy-menu-icl__dropdown-more-links-heading {
  color: #57606c;
}

.c-legacy-region-modal-icl__subheading::before {
  background: #10a8ba;
  content: '';
  display: block;
  height: 2px;
  margin-bottom: 5px;
  width: 30px;
}

.c-legacy-footer-icl {
  background-color: #2e3030;
  color: #909495;
  letter-spacing: 0.78px;
}

.c-legacy-footer-icl a {
  color: #d9dedf;
  transition: color 0.2s ease-out;
}

.c-legacy-footer-icl a:hover {
  color: #fff;
}

.c-legacy-footer-icl__primary {
  font-size: 13px;
}

.c-legacy-footer-icl__secondary {
  font-size: 12px;
  border-top: 1px solid rgba(217, 222, 223, 0.1);
}

@media (min-width: 1280px) {
  .c-legacy-footer-icl__secondary {
    border-top: none;
  }
}

.c-legacy-footer-icl__primary-link {
  margin-left: 32px;
}

.c-legacy-footer-icl__primary-link:first-child {
  margin-left: 0;
}

.c-legacy-footer-icl__secondary-link {
  display: inline-block;
  margin-left: 12px;
  position: relative;
}

.c-legacy-footer-icl__secondary-link::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 80%;
  background-color: #909495;
  top: 50%;
  transform: translateY(-50%);
  left: -8px;
}

.c-legacy-footer-icl__secondary-link:first-child {
  margin-left: 0;
}

.c-legacy-footer-icl__secondary-link:first-child::before {
  display: none;
}

.c-legacy-footer-icl__social-link {
  width: 25px;
  height: 25px;
  margin-left: 40px;
}

@media (min-width: 1280px) {
  .c-legacy-footer-icl__social-link {
    margin-left: 14px;
  }
}

.c-legacy-footer-icl__social-link:first-child {
  margin-left: 0;
}

a.c-legacy-footer-icl__social-link {
  color: #909495;
}

.c-legacy-footer-icl__social-icon {
  width: 100%;
}

.c-legacy-safety-info__html-formatting p {
  margin-bottom: 12px;
}

.c-legacy-safety-info__html-formatting b,
.c-legacy-safety-info__html-formatting strong {
  font-weight: 600;
}

.c-legacy-safety-info__html-formatting i {
  font-style: italic;
}

.c-legacy-safety-info__html-formatting ul {
  list-style-type: disc;
  margin-bottom: 12px;
  margin-left: 24px;
}

.c-legacy-safety-info__html-formatting ol {
  list-style-type: decimal;
  margin-bottom: 12px;
  margin-left: 24px;
}

.c-procedure-steps {
 padding-top: 10%;
}

.procedure-steps__step--background-image {
  margin-left: 15%;
  max-width: 305px;
  min-height: 400px;
  height: 100%;
}

.c-procedure-steps__mobile {
  display: none;
}

.procedure-steps__step {
  padding: 5%;
}

.procedure-steps__step.bg-blue-wave {
  position: relative;
  background: url(/_next/static/media/wave-contact-bottom.5c8d0c92.svg) no-repeat center / cover;
  z-index: 1;
  margin-top: -55px;
  padding: 110px 5%;
}

.procedure-steps__step.bg-blue-wave::after {
  content: '';
  background: url(/_next/static/media/wave-contact-top.838c3492.svg) no-repeat center / cover;
  position: absolute;
  top: 8px;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: -1;
}

.c-procedure-steps__right-side {
  width: 50%;
  max-width: 600px;
}

.c-procedure-steps__right-side > h3 {
  letter-spacing: 0.1em;
  padding-bottom: 10px;
}

.c-procedure-steps__right-side > a{
  display: block;
  min-width: 317px;
  font-size: 16px;
}

.c-procedure-steps__description strong{
  font-weight: 600;
}

.c-procedure-steps__description li {
  position: relative;
  padding-left: 15px;
}

.c-procedure-steps__description li:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 8px;
}

.procedure-steps__step--background-image > div > .a-video {
  height: 500px;
  width: 300px;
}

.c-procedure-steps-wrapper > div > .a-video {
  background-color: transparent;
}

/* Stepper CSS */

:root {
  --circle-size: clamp(1rem, 4vw, 2rem);
  --spacing: clamp(0rem, 0vw, 0rem);
}

.c-stepper {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  position: relative;
}

.c-stepper__item:before {
  --size: 3rem;
  content: "";
  display: block;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background-color: #10A8BA;
  margin: 0 auto 1rem;
}

ol.c-stepper > li:nth-child(5):before {
  margin: 0.35rem auto 1rem;
}

.c-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: calc(var(--circle-size) / 2);
  width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
  left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
  height: 7px;
  background-color: #F5F5F5;
  order: -1;
}

.c-stepper__title {
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin-bottom: 0.5rem;
  max-width: 180px;
  margin: 0 auto;
}

.c-stepper__desc {
  color: grey;
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}

.c-stepper__number {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1rem; 
}

.c-stepper__messageBox {
  background-color: #10A8BA;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: -55%;
  min-width: 100px;
  min-height: 58px;
  padding: 5px 8px;
  border-radius: 3px;
}

.c-stepper__messageBox p:first-child {
  font-size: 14px;
  font-weight: 300;
}

.c-stepper__messageBox-pointer {
  background-color: #10A8BA;
  height: 50px;
  width: 50px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
  top: 62%;
}

.c-timeline {
  display: none;
}

.c-procedure-steps__mobile-sticky {
  display: none;
}

/* Responsive */

@media (max-width: 1024px) {

  .procedure-steps__step--background-image {
    margin-left: 5%;
  }
}

@media (max-width: 767px) {
  .c-procedure-steps {
    padding-top: 0;
   }

  .procedure-steps__step--background-image {
    margin: 0 auto;
    max-width: unset;
    min-height: unset;
  }

  .c-procedure-steps__right-side > div > ul {
    margin-left: 15px;
  }

  .c-procedure-steps__mobile {
    display: block;
  }

  .procedure-steps__step.bg-blue-wave {
    padding: 120px 5% 70px;
  }

  .procedure-steps__step {
    padding: 50px 5%;
  }

  .c-procedure-steps__right-side {
    max-width: unset;
    margin-top: 25px;
    width: 100%;
  }

  .c-procedure-steps__right-side > h3, .c-procedure-steps__right-side > h1 {
    display: none;
  }

  .procedure-steps__step {
    display: block;
  }

  .c-stepper {
    display: none;
  }

  .c-timeline {
    display: block;
    position: sticky;
    bottom: 0;
    padding-top: 5%;
    padding-left: 40%
  }
  
  .c-timeline__item {
    position: relative;
    display: flex;
    gap: 1.5rem;
    
  }
  
  .c-timeline__number {
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 2;
    color: white;
  }
  
  .c-timeline__item:last-child .c-timeline__content:before {
    display: none;
  }
  
  .c-timeline__content {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 1.5rem;
    padding-bottom: 3rem;
  }
  
  .c-timeline__content:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 7px;
      background-color: #F5F5F5;
    }
  
    .c-timeline__content:after {
      content: "";
      position: absolute;
      left: calc(0px - 11px);
      top: 0;
      width: 30px;
      height: 30px;
      background-color: #10A8BA;
      z-index: 1;
      border-radius: 50%;
    }
  
  
  .c-timeline__title {
    margin-top: 0.2rem;
    margin-left: 0.5rem;
  }

  .c-timeline__messageBox {
    background-color: #10A8BA;
    position: absolute;
    left: -80px;
    transform: translate(-50%, -50%);
    top: 20%;
    min-width: 100px;
    padding: 7px 8px;
    border-radius: 3px;
    text-align: center;
  }

  .c-timeline__messageBox-pointer:not(.active-step) {
    background-color: #10A8BA;
    height: 12px;
    width: 50px;
    position: absolute;
    right: -37%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -1;
    top: 35%;
  }

  .c-timeline > li:nth-child(1) > .c-timeline__content > span {
    left: 1px;
  }
  
  time {
    text-align: end;
    flex: 0 0 100px;
    min-width: 0;
    overflow-wrap: break-word;
    padding-bottom: 1rem;
  }

  .c-procedure-steps__mobile-sticky {
    position: fixed;
    bottom: 0;
    z-index: 3;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .c-procedure-steps__mobile-sticky > .white-box, .c-procedure-steps__mobile-sticky.open > div > .white-box  {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .c-procedure-steps__mobile-sticky > .white-box > p {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .c-procedure-steps__mobile-sticky > .white-box button,
  .c-procedure-steps__mobile-sticky.open > div > div > button {
    border: none;
    color: #10A8BA;
    z-index: 10;
  }

  .c-procedure-steps__mobile-sticky > .white-box button:hover,
  .c-procedure-steps__mobile-sticky.open > div > div > button:hover {
    background-color: transparent;
  }

  .c-procedure-steps__mobile-sticky > .blue-box,  .c-procedure-steps__mobile-sticky.open > div > .blue-box {
    background-color: #10A8BA;
    height: 100%;
    padding: 10px 5px;
    min-width: 120px;
    border-radius: 3px;
    color: white;
    text-align: center;
  }

  .c-procedure-steps__mobile-sticky > .blue-box  > p:first-child,
  .c-procedure-steps__mobile-sticky.open > div > .blue-box  > p:first-child {
    font-size: 14px;
  }

  .c-procedure-steps__mobile-sticky.open {
    height: 330px;
    width: 100%;
    background-color: white;
    flex-wrap: wrap;
  }

  .c-procedure-steps__mobile-sticky.open > div {
    width: 100%;
    height: 60px;
    display: flex;
  }

  .c-procedure-steps__mobile-sticky.open > div > .blue-box {
    background-color: #F5F5F5;
    color: #10A8BA;
  }

  .c-procedure-steps__mobile-sticky > div > .blue-box  > p:last-child {
    font-weight: 600;
    text-transform: capitalize;
  }

  .c-procedure-steps__mobile-sticky.open > div > .white-box {
    padding-left: 60px;
  }

  .c-procedure-steps__mobile-sticky.open > .active-step > .blue-box {
    background-color: #10A8BA;
    color: white;
  }

 .c-timeline__messageBox-pointer.active-step, .c-procedure-steps__mobile-sticky > .blue-box > .c-timeline__messageBox-pointer {
    z-index: 5;
    top: 22px;
    left: 110px;
    height: 20px;
    background-color: #10A8BA;
    width: 40px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  /* @media (min-width: 1300px) {
    .c-procedure-steps__right-side {
      max-width: 700px;
    }
  } */
 
}

